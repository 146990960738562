import React from "react";
import "./Promo.scss";
import flyer from "../../media/flyer.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Promo = () => {
  const promoItems = [
    "Tanpa DP",
    "Discount Up to 13%",
    "Voucher Travel",
    "Subsidi Biaya KPR",
    "Free Smarthome System",
    "Instant Approval KPR",
  ];
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <section id="promo" className="promo-section">
      <div className="promo-slider">
        <img src={flyer} alt="" className="flyer-image" />
      </div>

      <div className="promo-content">
        <h3>PROMO TERBARU</h3>
        <div className="promo-description">
          {promoItems.map((item) => (
            <div className="promo-point">
              <FontAwesomeIcon
                icon={faCheckDouble}
                style={{ color: "FFFEFB", width: "30px", height: "30px" }}
              />
              <span style={{ color: "#FFFEFB" }}>&nbsp;&nbsp;{item}</span>
            </div>
          ))}
        </div>
        <div className="button-wa-promo">
          <button onClick={wapromo} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} />
            <span>Dapatkan Promo Terbaru</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Promo;
