import React from "react";
import About from "../../section/About/About";
import Header from "../../section/Header/Header";
import Promo from "../../section/Promo/Promo";
import Rumah from "../../section/Rumah/Rumah";
import Lokasi from "../../section/Lokasi/Lokasi";
import Ruko from "../../section/Ruko/Ruko";
import Footer from "../../section/Footer/Footer";
import Surrounding from "../../section/Surrounding/Surrounding";
import Fasilitas from "../../section/Fasilitas/Fasilitas";
import ScrollToAnchor from "./ScrollToAnchor.jsx";
import Newlaunch from "../../section/Newlaunch/Newlaunch.jsx";

const Home = () => {
  return (
    <>
      <ScrollToAnchor />
      <Header />
      <About />
      <Newlaunch />
      <Fasilitas />

      <Promo />
      <Rumah />
      <Ruko />
      <Surrounding />
      <Lokasi />
      <Footer />
    </>
  );
};

export default Home;
