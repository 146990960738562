import facilities1 from "../../media/fasilitas/facilities1.webp";
import facilities2 from "../../media/fasilitas/facilities2.webp";
import facilities3 from "../../media/fasilitas/facilities3.webp";
import facilities4 from "../../media/fasilitas/facilities4.webp";
import facilities5 from "../../media/fasilitas/facilities5.webp";
import facilities6 from "../../media/fasilitas/facilities6.webp";
import facilities7 from "../../media/fasilitas/facilities7.webp";
import facilities8 from "../../media/fasilitas/facilities8.webp";
import facilities9 from "../../media/fasilitas/facilities9.webp";
const fasilitas = [
  facilities1,
  facilities2,
  facilities3,
  facilities4,
  facilities5,
  facilities6,
  facilities7,
  facilities8,
  facilities9,
];
const fasilitasImages = fasilitas.reverse();

export default fasilitasImages;
