import nsd1 from "../../media/ruko/NSD-1.webp";
import nsd2 from "../../media/ruko/NSD-2.webp";
import nsd3 from "../../media/ruko/NSD-3.webp";
import nsd4 from "../../media/ruko/NSD-4.webp";
import nsd5 from "../../media/ruko/NSD-5.webp";
import nsd6 from "../../media/ruko/NSD-6.webp";
import nsd7 from "../../media/ruko/NSD-7.webp";
import nsd8 from "../../media/ruko/NSD-8.webp";
import nsd9 from "../../media/ruko/NSD-9.webp";
import nsd10 from "../../media/ruko/NSD-10.webp";
import nsd11 from "../../media/ruko/NSD-11.webp";
import nsd12 from "../../media/ruko/NSD-12.webp";
import nsd13 from "../../media/ruko/NSD-13.webp";
import nsdA1 from "../../media/ruko/21.webp";
import nsdA2 from "../../media/ruko/22.webp";
import nsdA3 from "../../media/ruko/23.webp";
import nsdC1 from "../../media/ruko/18.webp";
import nsdC2 from "../../media/ruko/19.webp";
import nsdC3 from "../../media/ruko/20.webp";
import nsdB1 from "../../media/ruko/24.webp";
import nsdB2 from "../../media/ruko/25.webp";
import dv1 from "../../media/ruko/DV1.webp";
import dv2 from "../../media/ruko/DV2.webp";
import dv3 from "../../media/ruko/DV3.webp";
import dv4 from "../../media/ruko/DV4.webp";
import dv5 from "../../media/ruko/DV5.webp";
import dv6 from "../../media/ruko/DV6.webp";
import dv7 from "../../media/ruko/DV7.webp";
import dv8 from "../../media/ruko/DV8.webp";
import dv9 from "../../media/ruko/DV9.webp";

const nsdSlider = [
  nsd1,
  nsd2,
  nsd3,
  nsd4,
  nsd5,
  nsd6,
  nsd7,
  nsd8,
  nsd9,
  nsd10,
  nsd11,
  nsd12,
  nsd13,
];

const nsdUnit = [
  {
    name: "tipe A",
    unit: "RUKO NSD A",
    lantai: "2",
    kamarMandi: "2",
    luasTanah: "65",
    luasBangunan: "118",
    dp: "tanpa dp",
    images: [nsdA1, nsdA2, nsdA3],
  },
  {
    name: "tipe B",
    unit: "RUKO NSD B",
    lantai: "2",
    kamarMandi: "2",
    luasTanah: "50",
    luasBangunan: "105",
    dp: "tanpa dp",
    images: [nsdB1, nsdB2],
  },
  {
    name: "tipe C",
    unit: "RUKO NSD C",
    lantai: "3",
    kamarMandi: "3",
    luasTanah: "106",
    luasBangunan: "255",
    dp: "tanpa dp",
    images: [nsdC1, nsdC2, nsdC3],
  },
];

const dvSlider = [dv1, dv2, dv3, dv4, dv5, dv6, dv7, dv8, dv9];
const dvPoint1 = [
  "Tepat di Entrance Tol Kota Wisata dan Main Gate",
  "Semua unit Menghadapi Boulevard (ROW 39)",
  "Dekat dengan commercial area, sekolah, rumah sakit, mall dan premium cluster",
  "Konsep terbaru diarea Cibubur",
];
const dvPoint2 = [
  "4 lantai Free commercial Lift",
  "Free biaya pengalihan 1x",
  "Free AC 2PK 5 Unit",
  "Free biaya KPR",
];
const dvUnit = [
  {
    name: "devant",
    unit: "RUKO DEVANT",
    lantai: "4",
    kamarMandi: "4",
    luasTanah: "120",
    luasBangunan: "426",
    dp: "tanpa dp",
  },
  {
    name: "devant",
    unit: "RUKO DEVANT",
    lantai: "4",
    kamarMandi: "4",
    luasTanah: "124",
    luasBangunan: "426",
    dp: "tanpa dp",
  },
  {
    name: "devant",
    unit: "RUKO DEVANT",
    lantai: "4",
    kamarMandi: "4",
    luasTanah: "125",
    luasBangunan: "426",
    dp: "tanpa dp",
  },
  {
    name: "devant",
    unit: "RUKO DEVANT",
    lantai: "4",
    kamarMandi: "4",
    luasTanah: "126",
    luasBangunan: "426",
    dp: "tanpa dp",
  },
  {
    name: "devant",
    unit: "RUKO DEVANT",
    lantai: "4",
    kamarMandi: "4",
    luasTanah: "127",
    luasBangunan: "426",
    dp: "tanpa dp",
  },
  {
    name: "devant",
    unit: "RUKO DEVANT",
    lantai: "4",
    kamarMandi: "4",
    luasTanah: "128",
    luasBangunan: "426",
    dp: "tanpa dp",
  },
];

export { nsdSlider, nsdUnit, dvSlider, dvPoint1, dvPoint2, dvUnit };
