import React from "react";
import "./Lokasi.scss";
import map from "../../media/kota-wisata-map.png";

const Lokasi = () => {
  return (
    <section id="lokasi" className="lokasi-section">
      <h1 className="lokasi-title">LOKASI KOTA WISATA</h1>
      <div className="lokasi-container">
        <div className="image-container">
          <img src={map} className="lokasi-image" alt="lokasi kota wisata" />
        </div>
        <div className="lokasi-description">
          <ul>
            <li>3 Menit Mall Living World</li>
            <li>3 Menit Pintu Tol</li>
            <li>3 Menit RS Eka Hospital</li>
            <li>2 Menit Sekolah BPK Penabur</li>
            <li>2 Menit Mcd, Starbucks, BK, Market City</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Lokasi;
