import "./App.scss";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  const [showWhatsApp, setShowWhatsApp] = useState(false);

  useEffect(() => {
    const header = document.querySelector(".section-header");

    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const headerBottom = header.offsetTop + header.offsetHeight;
      setShowWhatsApp(scrollPosition > headerBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>{" "}
        {showWhatsApp && (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=6281287877618&text=Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Kota Wisata)%20https://marketing-kotawisata.com/&type=phone_number&app_absent=0"
          >
            <FloatingWhatsApp />
          </a>
        )}
      </Router>
    </div>
  );
}

export default App;
