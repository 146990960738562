import React from "react";
import "./About.scss";

const About = () => {
  return (
    <section id="about" className="section-about">
      <div className="container-about">
        <h1 className="section-title">KAWASAN MANDIRI CIBUBUR</h1>
        <p>
          Kota Wisata adalah proyek pengembangan kota oleh Sinarmas Land, yang
          terletak di Cibubur, Indonesia. Kota ini berjarak 6 km dari gerbang
          tol Cibubur dan dikelilingi oleh berbagai destinasi wisata, seperti
          “Taman Buah Mekarsari”. Proyek ini menawarkan berbagai klaster
          properti perumahan, termasuk penambahan terbaru, yaitu Atherton, yang
          menawarkan berbagai fasilitas dan amenitas. Lokasi Kota Wisata Cibubur
          mudah diakses melalui Tol Kota Wisata dan Narogong.
        </p>
      </div>
    </section>
  );
};

export default About;
