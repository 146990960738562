import sr1 from "../../media/surrounding/sr1.webp";
import sr2 from "../../media/surrounding/sr2.webp";
import sr3 from "../../media/surrounding/sr3.webp";
import sr4 from "../../media/surrounding/sr4.webp";
import sr5 from "../../media/surrounding/sr5.webp";
import sr6 from "../../media/surrounding/sr6.webp";
import sr7 from "../../media/surrounding/sr7.webp";
import sr8 from "../../media/surrounding/sr8.webp";
import sr9 from "../../media/surrounding/sr9.webp";
import sr10 from "../../media/surrounding/sr10.webp";
import sr11 from "../../media/surrounding/sr11.webp";
import sr12 from "../../media/surrounding/sr12.webp";
import sr13 from "../../media/surrounding/sr13.webp";
import sr14 from "../../media/surrounding/sr14.webp";

const surroundingData = [
  sr1,
  sr2,
  sr3,
  sr4,
  sr5,
  sr6,
  sr7,
  sr8,
  sr9,
  sr10,
  sr11,
  sr12,
  sr13,
  sr14,
];
export default surroundingData;
